export const CheckCookies = () => {
  if (typeof window != 'undefined' && typeof navigator === 'object') {
    if (!navigator?.cookieEnabled) {
      window.location.href = '/enable-cookies';
      // console.log('disabled');
      return false;
    } else {
      // console.log('ena');
      return true;
    }
  } else {
    return false;
  }
};
