import nookies from 'nookies';
import formatTitle from 'title';
import { NextPageContext } from 'next';
import base64 from 'base-64';
import {
  ShopifyService,
  CheckoutLineItemInput,
  CheckoutLineItemUpdateInput,
  CurrencyCode,
  MailingAddressInput,
  AvailableShippingRates,
  GetCartQuery,
} from '@app/services/shopify.service';
const CHECKOUT_ID = 'CHECKOUT_ID';
const BOX_CHECKOUT_ID = 'BOX_CHECKOUT_ID';
import { SHIPPING_ADDRESS } from '@app/utilities/constants';
import { LocalStorageService } from '@app/services/localstorage.service';
import { QueryClient } from 'react-query';
import { getQueryKey } from '@app/utilities/common.utility';
import { isMobileWebView } from '@app/utilities/common.utility';
import { CheckoutCreateInput } from './shopify/generated';
import { getUserDetailFromReduxStore } from '@app/state';

export namespace CartService {
  export interface CartItem {
    id: string;
    title: string;
    quantity: number;
    variant: {
      id: string;
      title: string;
      url: string;
      product: {
        handle: string;
        vendor: string;
        productType: string;
      };
      price: {
        amount: number;
        currencyCode: CurrencyCode;
      };
      compareAtPriceV2: {
        amount: number;
      };
      image: {
        src: string;
        alt: string;
      };
    };
  }

  export type Cart = {
    items: CartItem[];
    subtotal: {
      amount: number;
      currencyCode: CurrencyCode;
    };
    tax: {
      amount: number;
      currencyCode: CurrencyCode;
    };
    total: {
      amount: number;
      currencyCode: CurrencyCode;
    };
    lineItemsSubtotalPrice: {
      amount: number;
    };
    url: string;
    totalCompareAtPrice: number;
    discountOnMrp: number;
    shippingCharges: number;
    availableShippingRates: AvailableShippingRates;
    discountApplicable?: boolean;
    discountCode?: string;
    discountAmt?: string;
    shippingAddress: {
      firstName?: string;
      lastName?: string;
      address1?: string;
      address2?: string;
      city?: string;
      province?: string;
      country?: string;
      zip?: string;
    } | null;
    isShippingDiscountApplicable: boolean;
    discountTargetType?: string;
  };

  export const setCheckoutId = (isBoxProduct?: boolean, checkoutId?: string, context?: NextPageContext) => {
    if (isMobileWebView()) {
      if (isBoxProduct) {
        localStorage.setItem('MOBILE_BOX_CHECKOUT_ID', checkoutId!);
      } else {
        localStorage.setItem('MOBILE_CHECKOUT_ID', checkoutId!);
      }
    }

    if (isBoxProduct) {
      nookies.set(context, BOX_CHECKOUT_ID, checkoutId!, { maxAge: 30 * 24 * 60 * 60, path: '/' });
    } else {
      nookies.set(context, CHECKOUT_ID, checkoutId!, { maxAge: 30 * 24 * 60 * 60, path: '/' });
    }
  };

  export const getCheckoutId = (isBoxProduct?: boolean, context?: NextPageContext) => {
    if (isMobileWebView()) {
      let checkoutId = localStorage.getItem('MOBILE_CHECKOUT_ID');
      if (isBoxProduct) {
        checkoutId = localStorage.getItem('MOBILE_BOX_CHECKOUT_ID');
      }
      return checkoutId;
    }

    let checkoutId = nookies.get(context, CHECKOUT_ID).CHECKOUT_ID;
    if (isBoxProduct) {
      checkoutId = nookies.get(context, BOX_CHECKOUT_ID).BOX_CHECKOUT_ID;
    }
    return checkoutId;
  };

  export const getCartItemObj = (node: GetCartQuery['node']) => {
    if (node?.__typename === 'Checkout') {
      let TotalMrp = 0;
      const items: CartItem[] = node.lineItems.edges.map(({ node }) => {
        TotalMrp +=
          node.quantity * (Number(node.variant?.compareAtPrice?.amount) || Number(node?.variant?.price.amount));

        const item: CartItem = {
          id: node.id,
          title: formatTitle(node.title),
          quantity: node.quantity,
          variant: {
            id: node.variant?.id!,
            title: node.variant?.title!,
            url: `/products/${node.variant?.product.handle}`,
            product: {
              handle: node.variant?.product.handle!,
              vendor: node.variant?.product?.vendor!,
              productType: node.variant?.product?.productType!,
            },
            price: {
              amount: Number(node.variant?.price?.amount),
              currencyCode: node.variant?.price?.currencyCode!,
            },
            compareAtPriceV2: {
              amount: Number(node.variant?.compareAtPrice?.amount),
            },
            image: {
              src: node.variant?.image?.url!,
              alt: node.variant?.image?.altText || '',
            },
          },
        };

        return item;
      });

      let discountApplicable;
      let discountCode;
      let discountAmt;
      let discountTargetType;
      let isShippingDiscountApplicable = false;

      if (node?.discountApplications?.edges?.[0]?.node.__typename === 'DiscountCodeApplication') {
        discountApplicable = node?.discountApplications?.edges?.[0]?.node?.applicable;
        discountCode = node?.discountApplications?.edges?.[0]?.node?.code;
        discountTargetType = node?.discountApplications?.edges?.[0]?.node?.targetType;
        isShippingDiscountApplicable = discountApplicable && discountTargetType === 'SHIPPING_LINE';
        if (node?.discountApplications?.edges?.[0]?.node?.value?.__typename === 'MoneyV2') {
          discountAmt = node?.discountApplications?.edges?.[0]?.node?.value?.amount;
        }
      }

      return {
        items,
        shippingAddress: node?.shippingAddress,
        url: node.webUrl,
        subtotal: {
          amount: Number(node.subtotalPrice.amount),
          currencyCode: node.subtotalPrice.currencyCode,
        },
        tax: {
          amount: Number(node.totalTax.amount),
          currencyCode: node.totalTax.currencyCode,
        },
        total: {
          amount: Number(node.totalPrice.amount),
          currencyCode: node.totalPrice.currencyCode,
        },
        lineItemsSubtotalPrice: {
          amount: Number(node.lineItemsSubtotalPrice.amount),
        },
        totalCompareAtPrice: TotalMrp,
        discountOnMrp: TotalMrp - Number(node.lineItemsSubtotalPrice.amount),
        shippingCharges: node?.shippingLine?.price?.amount ? Number(node?.shippingLine?.price?.amount) : 0,
        availableShippingRates: node?.availableShippingRates as AvailableShippingRates,
        discountApplicable,
        discountCode,
        discountAmt,
        isShippingDiscountApplicable,
        discountTargetType,
      };
    }
  };

  export async function getCart(context?: NextPageContext, isBoxProduct?: boolean): Promise<Cart | undefined> {
    let showShipRate = false;
    try {
      const checkoutId = getCheckoutId(isBoxProduct);

      const addr = localStorage.getItem(SHIPPING_ADDRESS);
      if (addr) {
        showShipRate = false;
      }
      if (checkoutId) {
        const { node } = await ShopifyService.getCart({ checkoutId, showShipRate });
        return getCartItemObj(node);
      }
    } catch (error: any) {
      console.log('error12', error);

      if (
        error?.message &&
        typeof error?.message === 'string' &&
        error?.message?.includes("Shipping address can't be blank")
      ) {
        if (localStorage.getItem(SHIPPING_ADDRESS)) {
          localStorage.setItem(SHIPPING_ADDRESS, '');
        }
      }
    }
  }

  export async function getItemCount(context?: NextPageContext, isBoxProduct?: boolean): Promise<number> {
    let count: number = 0;
    const checkoutId = getCheckoutId(isBoxProduct);

    if (checkoutId) {
      const { node } = await ShopifyService.getCartItemCount({ checkoutId });

      if (node?.__typename === 'Checkout') {
        count = node?.lineItems?.edges?.length || 0;
        // node?.lineItems.edges.forEach((lineItem) => {
        //   count += lineItem.node.quantity;
        // });
      }
    }

    return count;
  }

  export async function addItem(
    lineItem: CheckoutLineItemInput & { isBoxProduct?: boolean },
    context?: NextPageContext
  ): Promise<Cart | undefined> {
    const { isBoxProduct } = lineItem;
    delete lineItem.isBoxProduct;
    // delete lineItem.phone;
    console.log('track addItem', isBoxProduct);
    try {
      const checkoutId = getCheckoutId(isBoxProduct);
      const res = await ShopifyService.addCartItem({ checkoutId, lineItem });
      if (!res.checkoutLineItemsAdd?.checkout) {
        nookies.destroy(null, 'CHECKOUT_ID', { path: '/' });
        nookies.destroy(null, 'BOX_CHECKOUT_ID', { path: '/' });
        localStorage.clear();
        throw Error('clean cache');
      }
      return getCartItemObj(res.checkoutLineItemsAdd.checkout);
    } catch (error) {
      const additionalAttribute: CheckoutCreateInput = {};
      const userDetail = getUserDetailFromReduxStore();
      if (userDetail?.phoneNumber) {
        additionalAttribute['customAttributes'] = [
          { key: 'phone', value: userDetail?.phoneNumber },
          { key: 'completed', value: '0' },
          { key: 'name', value: userDetail?.name || '' },
        ];
        if (userDetail?.email) {
          additionalAttribute['customAttributes'].push({ key: 'email', value: userDetail?.email });
          additionalAttribute.email = userDetail?.email;
        }
      }
      const { checkoutCreate } = await ShopifyService.createCart({
        input: {
          lineItems: [lineItem],
          ...additionalAttribute,
        },
      });
      const checkoutId_base64format = base64.encode(checkoutCreate?.checkout?.id).toString();
      setCheckoutId(isBoxProduct, checkoutId_base64format);

      console.log(checkoutCreate, 'checkoutCreate');

      return getCartItemObj(checkoutCreate?.checkout);
      // updateShippingAddress({
      //   "lastName": "Doe",
      //   "firstName": "John",
      //   "address1": "123 Test Street",
      //   "province": "QC",
      //   "country": "Canada",
      //   "zip": "H3K0X2",
      //   "city": "Montreal",
      //   "phone":"+919049340586"
      // })
    }
  }

  export async function updateItem(
    lineItem: CheckoutLineItemUpdateInput & { isBoxProduct?: boolean },
    context?: NextPageContext
  ): Promise<Cart | undefined> {
    const { isBoxProduct } = lineItem;
    delete lineItem.isBoxProduct;
    const checkoutId = getCheckoutId(isBoxProduct);

    const res = await ShopifyService.updateCartItem({ checkoutId, lineItem });
    return getCartItemObj(res?.checkoutLineItemsUpdate?.checkout);
  }

  export async function removeItem(
    variables: { lineItemId: string | string[]; isBoxProduct?: boolean },
    context?: NextPageContext
  ): Promise<Cart | undefined> {
    const { lineItemId, isBoxProduct } = variables;
    const checkoutId = getCheckoutId(isBoxProduct);

    const res = await ShopifyService.removeCartItem({ checkoutId, lineItemId });
    return getCartItemObj(res?.checkoutLineItemsRemove?.checkout);
  }

  export async function addCouponToOrder(discountCode: string, isBoxProduct: boolean, context?: NextPageContext) {
    try {
      const checkoutId = getCheckoutId(isBoxProduct);
      return await ShopifyService.ApplyDiscountCouponToCheckout({ checkoutId, discountCode });
    } catch (err) {
      console.log(err);
    }
  }

  export async function removeCouponFromOrder(isBoxProduct?: boolean, context?: NextPageContext) {
    try {
      const checkoutId = getCheckoutId(isBoxProduct);
      return await ShopifyService.RemoveDiscountCouponFromCheckout({ checkoutId });
    } catch (err) {
      console.log(err);
    }
  }

  export async function getShippingRates(isBoxProduct?: boolean, context?: NextPageContext) {
    const checkoutId = getCheckoutId(isBoxProduct);

    const rates = await ShopifyService.getShippingRates({ checkoutId });
    return rates;
  }

  export async function shippingAddressUpdate(
    shippingAddress: object,
    isBoxProduct?: boolean,
    queryClient: QueryClient,
    context?: NextPageContext
  ) {
    try {
      const cart_item_count_query = getQueryKey('cart_item_count_query', isBoxProduct!);
      const cart_query = getQueryKey('cart_query', isBoxProduct!);

      const checkoutId = getCheckoutId(isBoxProduct);

      const res = await ShopifyService.checkoutShippingAddressUpdateV2({ shippingAddress, checkoutId });

      if (!res?.checkoutShippingAddressUpdateV2?.userErrors?.length) {
        if (res?.checkoutShippingAddressUpdateV2?.checkout) {
          // update query
          const cartRes = CartService.getCartItemObj(res?.checkoutShippingAddressUpdateV2?.checkout);
          const count = cartRes?.items?.length || 0;
          queryClient.setQueryData(cart_item_count_query, count);
          queryClient.setQueryData(cart_query, cartRes);
          LocalStorageService.writeToStorage(cart_item_count_query, JSON.stringify(count));
          LocalStorageService.writeToStorage(cart_query, JSON.stringify(cartRes));
        }
        if (res?.checkoutShippingAddressUpdateV2?.checkout?.lineItems?.edges?.length) {
          try {
            const rates = await getShippingRates(isBoxProduct);
            if (rates?.node?.__typename === 'Checkout') {
              const shippingRateHandle = rates?.node?.availableShippingRates?.shippingRates?.[0]?.handle;
              if (shippingRateHandle) {
                const updatedShipCharges = await updateShippingCharges(shippingRateHandle, isBoxProduct);
                if (updatedShipCharges?.checkoutShippingLineUpdate?.checkout) {
                  // update query
                  const cartRes = CartService.getCartItemObj(updatedShipCharges?.checkoutShippingLineUpdate?.checkout);
                  const count = cartRes?.items?.length || 0;
                  queryClient.setQueryData(cart_item_count_query, count);
                  queryClient.setQueryData(cart_query, cartRes);
                  LocalStorageService.writeToStorage(cart_item_count_query, JSON.stringify(count));
                  LocalStorageService.writeToStorage(cart_query, JSON.stringify(cartRes));
                }
              }
            }
          } catch (error) {
            return res;
          }
        }
      }
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  export async function updateShippingCharges(
    shippingRateHandle: string,
    isBoxProduct?: boolean,
    context?: NextPageContext
  ) {
    try {
      if (!shippingRateHandle) {
        return;
      }
      const checkoutId = getCheckoutId(isBoxProduct);
      const updatedShippingCharges = await ShopifyService.checkoutShippingLineUpdate({
        checkoutId,
        shippingRateHandle,
      });
      console.log('updatedShippingCharges', updatedShippingCharges);

      return updatedShippingCharges;
    } catch (err) {
      console.log(err);
    }
  }

  // export async function updateShippingAddress(
  //   shippingAddress: MailingAddressInput,
  //   context?: NextPageContext
  // ): Promise<void> {
  //   const checkoutId = nookies.get(context, CHECKOUT_ID).CHECKOUT_ID;
  //   await ShopifyService.checkoutShippingAddressUpdateV2({ shippingAddress, checkoutId });
  // }
}
