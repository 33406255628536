import { CheckCookies } from '@app/utilities/cookiesCheck';

export namespace LocalStorageService {
  export const writeToStorage = async (queryKey: string, data: string): Promise<void> => {
    if (CheckCookies()) {
      localStorage.setItem(queryKey, data);
    }
  };

  export const readFromStorage = (queryKey: string) => {
    if (CheckCookies()) {
      const storageData = localStorage.getItem(queryKey);
      if (storageData) {
        return JSON.parse(storageData);
      }
    }
  };
}
